* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

.dot {
  position: fixed;
  width: 8em;
  height: 8rem;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 50% 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  z-index: 1;
  width: 15px;
  height: 15px;
}

/* Define the animation */
@keyframes shadowAnimation {
  from {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }
  to {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.7));
  }
}
/* Define the animation */
@keyframes shadowAnimation {
  from {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }
  to {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.7));
  }
}
.default-padding {
  padding: 4.5rem 0;
}

.default-padding-top {
  padding-top: 4.5rem;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0rem !important;
}

.section-title {
  color: #202020;
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
}

.section-description {
  color: #686a6f;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 30px;
  width: 100%;
}

/* Custom button */
.custom-btn {
  padding: 15px 30px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1199px) {
  .custom-btn {
    display: none;
  }
}

.bx-btn,
.bx-btn-1 {
  border: none;
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
  color: #fff;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
}

.bx-btn:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}

.bx-btn:hover,
.bx-btn-1:hover {
  opacity: 0.7;
  text-decoration: none;
  color: #fff;
}

.bx-contact-section .custom-btn {
  margin-top: 30px;
}

.bx-btn:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
#header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 9999;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fff;
}
#header.active {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0 0 5px #cdcdcd;
}
#header .burger-icon {
  display: none;
  z-index: 99;
}
#header .burger-icon svg {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  cursor: pointer;
}
#header .burger-icon svg path {
  fill: none;
  stroke: #33a2d4;
  stroke-width: 3px;
}
#header .burger-icon svg.active #top, #header .burger-icon svg.active #bottom {
  stroke-dasharray: 75.39;
  stroke-dashoffset: -60;
}
#header .burger-icon #top,
#header .burger-icon #bottom {
  stroke-dasharray: 30, 75.39;
  transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
}
#header .nav-footer-btns {
  display: flex;
  align-items: center;
  gap: 20px;
}
#header .nav-footer-btns .drop-down-language-menu {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#header .nav-footer-btns .drop-down-language-menu .language-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #33a2d4;
  border-radius: 4px;
  outline: none !important;
  padding: 15px 10px;
  cursor: pointer;
}
#header .nav-footer-btns .drop-down-language-menu .dropdown-menu-lg {
  position: absolute;
  top: 106%;
  left: 0%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #33a2d4;
  border-radius: 4px;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  display: none;
}
#header .nav-footer-btns .drop-down-language-menu .dropdown-menu-lg.active {
  display: block;
}
#header .nav-footer-btns .drop-down-language-menu .dropdown-menu-lg .dropdown-item {
  padding: 7px 0 !important;
}
#header .nav-footer-btns .drop-down-language-menu .dropdown-menu-lg .dropdown-item:last-child {
  position: relative;
}
#header .nav-footer-btns .drop-down-language-menu .dropdown-menu-lg .dropdown-item:last-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #cdcdcd;
}
#header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#header .container .brand-logo {
  width: 20%;
}
#header .container .navbar-nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
#header .container .navbar-nav .nav-items {
  display: inline-flex;
  margin-bottom: 0rem !important;
  gap: 20px;
}
#header .container .navbar-nav .nav-items .nav-item {
  list-style: none;
}
#header .container .navbar-nav .nav-items .nav-item a {
  color: #777;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.5);
}
#header .container .navbar-nav .nav-items .nav-item a:hover {
  font-weight: bold;
  letter-spacing: 2px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 400px;
  height: 100%;
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
  padding: 2rem 30px;
  overflow-y: scroll;
  display: none;
  transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 9999;
  align-items: center;
}
.sidebar.active {
  left: 0;
}
@media screen and (max-width: 995px) {
  .sidebar {
    display: block;
  }
}
@media screen and (max-width: 760px) {
  .sidebar {
    width: 65%;
  }
}
.sidebar .res-cross {
  position: absolute;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  top: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sidebar .res-cross:hover {
  transform: rotate(360deg);
}
.sidebar .res-cross:hover i {
  transform: rotate(-360deg);
}
.sidebar .res-cross i {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sidebar .res-cross i:hover {
  transition: all 0.4s ease-in;
  animation: spin 1s linear infinite;
}
.sidebar .side-links {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sidebar .side-links .nav-item {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  padding: 20px 10px;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sidebar .side-links .nav-item::before {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.5s cubic-bezier(0.78, 0.04, 0, 0.9);
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #33a2d4;
  z-index: -1;
}
.sidebar .side-links .nav-item:hover::before {
  width: 100%;
}
.sidebar .side-links .nav-item a {
  color: #fff;
}
.sidebar .side-links .nav-item a:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
  padding: 10px 0;
}
.sidebar .side-links .nav-item ul > li {
  font-size: 18px;
}
.sidebar .side-links .nav-item ul > li i {
  padding-right: 15px;
  text-align: left;
}
.sidebar::-webkit-scrollbar {
  width: 8px;
}
.sidebar::-webkit-scrollbar-track {
  background: #cdcdcd;
}
.sidebar::-webkit-scrollbar-thumb {
  background: #33a2d4;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2rem #33a2d4;
}

.bg---dark {
  background-color: rgba(90, 129, 255, 0.5411764706);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  transition: all 20s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.bg---dark.active {
  display: block;
}
@media (min-width: 993px) {
  .bg---dark {
    display: none !important;
  }
}

.hero-section {
  width: 100%;
  padding-top: 12rem;
  padding-bottom: 4rem;
  position: relative;
  height: 100vh;
  background: #000 url("assets/images/hero/1.png") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 780px) {
  .hero-section .text-center-mobile {
    text-align: center;
  }
  .hero-section .text-center-mobile > .d-flex {
    justify-content: center;
  }
}
.hero-section .hero-title,
.hero-section .hero-desc {
  color: #fff;
}
.hero-section .hero-title {
  font-size: 55px;
  font-weight: 700;
  letter-spacing: 1px;
}
.hero-section .hero-desc {
  font-size: 25px;
  padding: 20px 0;
}

.play-btn {
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
}
.play-btn::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
  z-index: 1;
  animation: pulse-border 1500ms ease-out infinite;
}
.play-btn img {
  width: 17px;
  z-index: 1;
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
#feature .features-gridx {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
#feature .features-gridx .single-feature {
  box-shadow: 0 0 2px 0px #777;
  width: 100%;
  padding: 20px;
  margin: 15px 7px;
}
#feature .features-gridx .single-feature:nth-child(2) {
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
  box-shadow: 0 0 2px 2px #777;
  color: #fff !important;
}
#feature .features-gridx .single-feature h4 {
  margin: 20px 0;
}
#feature .features-gridx .single-feature > .img-fluid {
  width: 60px;
}

#about ul li {
  position: relative;
  list-style: none;
  margin: 10px 0;
  padding-left: 40px;
}
#about ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: url(assets/images/about/list.svg) no-repeat;
  width: 80px;
  height: 80px;
}
#about .col-md-6 > figure {
  position: relative;
}
#about .col-md-6 > figure:hover img:first-child {
  right: -60px;
}
#about .col-md-6 > figure img:first-child {
  position: absolute;
  top: -20px;
  right: 0;
  width: 400px;
  height: auto;
  z-index: -1;
  transition: all 0.6s ease;
}
#about .col-md-6 > figure img:last-child {
  width: 500px;
  height: auto;
  margin: 6rem 0 0 0;
}

#video {
  position: relative;
}
#video figure {
  position: relative;
  height: 100%;
  width: 100%;
}
#video figure::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(32, 32, 32, 0.7607843137);
  height: 100%;
}
#video .play-btn {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#team {
  position: relative;
}
#team .team-slider {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1px;
}
#team .team-slider .team-member {
  width: 100%;
  margin: 10px 8px;
  position: relative;
}
#team .team-slider .team-member > img {
  filter: grayscale(1);
  transition: all 0.2s linear;
}
#team .team-slider .team-member:hover .main-info {
  bottom: 0;
  opacity: 1;
}
#team .team-slider .team-member:hover img {
  filter: grayscale(0);
}
#team .team-slider .team-member .main-info {
  text-align: center;
  padding: 10px 0;
  background-color: rgba(240, 248, 255, 0.733);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: -100%;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#team .team-slider .team-member .main-info h4 {
  font-weight: bold;
  color: #33a2d4;
}
#team .team-slider .team-member .main-info p {
  font-weight: 500;
  color: #777;
}
#team .team-slider .team-member .main-info .social-icons a > i {
  color: #777;
  font-size: 15px;
  padding-left: 6px;
  padding-top: 10px;
}

.single-blog .blog-img a {
  display: block;
}
.single-blog .blog-img a img {
  width: 100%;
}
.single-blog .blog-content {
  background-color: #fff;
  padding: 8px 0;
}
.single-blog .blog-content .blog-meta {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.single-blog .blog-content .blog-meta .blog-author {
  display: flex;
  align-items: center;
}
.single-blog .blog-content .blog-meta .blog-author img {
  border-radius: 50%;
  width: 50px;
}
.single-blog .blog-content .blog-meta .blog-author span {
  color: #313450;
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
}
.single-blog .blog-content .blog-meta .blog-comment {
  display: flex;
  align-items: center;
}
.single-blog .blog-content .blog-meta .blog-comment span {
  font-size: 16px;
  color: #505478;
  margin-left: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content .blog-meta .blog-comment {
    display: none;
  }
}
.single-blog .blog-title h4 {
  font-weight: 600;
}
.single-blog .blog-title h4 a {
  color: #777;
}

#contact label {
  margin-bottom: 10px;
  color: var(--thm-b-text);
  font-weight: 500;
}
#contact input[type=number],
#contact input[type=text],
#contact input[type=email],
#contact input[type=password],
#contact input[type=tel],
#contact input[type=url],
#contact input[type=search],
#contact select,
#contact textarea,
#contact .form-control {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  font-size: 16px;
  color: #686a6f;
  font-weight: 400;
  padding: 14px 30px;
  letter-spacing: 0.1em;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  border-radius: 0;
  transition: all 300ms ease;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
#contact .form-group {
  position: relative;
  margin-bottom: 20px;
}
#contact textarea {
  height: auto;
  resize: none;
  line-height: 1;
}
#contact .button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: #33a2d4;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  padding: 17px 30px;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 0;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
  width: 100%;
}
#contact .sigma_contact-map {
  width: 100%;
  height: 600px;
  position: relative;
  margin-top: 4rem;
}
#contact .sigma_contact-map iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

/* Footer Styles */
.footer {
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
  padding-top: 80px;
  padding-bottom: 40px;
  /* Newsletter Styles */
  /* Social Profile Styles */
  /* Copyright Styles */
}
@media only screen and (max-width: 768px) {
  .footer .single_footer {
    margin-bottom: 30px;
  }
}
.footer .single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}
.footer .single_footer h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background: #fff;
  margin-top: 20px;
}
.footer .single_footer p {
  color: #fff;
}
.footer .single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer .single_footer ul li a {
  color: #fff;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 15px;
  text-transform: capitalize;
}
.footer .single_footer ul li a:hover {
  color: #ff3666;
}
.footer .single_footer_address ul li {
  color: #fff;
}
.footer .single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}
.footer .contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.footer .subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}
.footer .subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .footer .subscribe__input {
    padding: 0 50px 0 20px;
  }
}
.footer .subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}
.footer .subscribe__btn i {
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 768px) {
  .footer .subscribe__btn {
    right: 0px;
  }
}
.footer .subscribe__btn:hover i {
  color: #ff3666;
}
.footer .subscribe button {
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.footer .social_profile {
  margin-top: 40px;
}
.footer .social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.footer .social_profile ul li {
  float: left;
}
.footer .social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 768px) {
  .footer .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .footer .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
.footer .social_profile ul li a:hover {
  background: #ff3666;
  border: 1px solid #ff3666;
  color: #fff;
  border: 0px;
}
.footer .copyright {
  margin-top: 70px;
  padding-top: 40px;
  color: #fff;
  font-size: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-align: center;
}
.footer .copyright a {
  color: #01c7e9;
  transition: all 0.2s ease 0s;
}
.footer .copyright a:hover {
  color: #ff3666;
}

#scrollUp {
  padding: 20px;
  position: fixed;
  right: -100px;
  bottom: 30px;
  z-index: 999;
  transition: all 0.6s linear;
  transform: rotate(-90deg);
  cursor: pointer;
}
#scrollUp.active {
  right: 10px;
  transform: rotate(-90deg);
}
#scrollUp img {
  border-radius: 50%;
  transform: rotate(90deg);
}
#scrollUp i {
  font-size: 20px;
  color: #33a2d4;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(105deg, #11ccb1, #33a2d4);
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.loading-screen img {
  animation: scaleAnimation 1.5s ease-in-out infinite;
  transition: all 0.3s linear;
  width: 100px;
}

@keyframes scaleAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}/*# sourceMappingURL=style.css.map */