* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
}

$mainColor: linear-gradient(105deg, #11ccb1, #33a2d4);
$secondaryColor: #33a2d4;
$blackColor: #202020;
$grayColor: #cacaca;

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

.dot {
  position: fixed;
  width: 8em;
  height: 8rem;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 50% 50%;
  user-select: none;
  pointer-events: none;
  z-index: 1;
  width: 15px;
  height: 15px;
}

/* Define the animation */
@keyframes shadowAnimation {
  from {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }
  to {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.7));
  }
}

/* Define the animation */
@keyframes shadowAnimation {
  from {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  }
  to {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.7));
  }
}
.default-padding {
  padding: 4.5rem 0;
}

.default-padding-top {
  padding-top: 4.5rem;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0rem !important;
}

.section-title {
  color: $blackColor;
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
}

.section-description {
  color: #686a6f;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 30px;
  width: 100%;
}

/* Custom button */
.custom-btn {
  padding: 15px 30px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.bx-btn,
.bx-btn-1 {
  border: none;
  background: $mainColor;
  color: #fff;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
}

.bx-btn:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}

.bx-btn:hover,
.bx-btn-1:hover {
  opacity: 0.7;
  text-decoration: none;
  color: #fff;
}

.bx-contact-section .custom-btn {
  margin-top: 30px;
}

.bx-btn:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

#header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 9999;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fff;
  &.active {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 5px #cdcdcd;
  }
  .burger-icon {
    display: none;
    z-index: 99;
    svg {
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 1.5;
      cursor: pointer;
      path {
        fill: none;
        stroke: $secondaryColor;
        stroke-width: 3px;
      }
      &.active #top,
      &.active #bottom {
        stroke-dasharray: 75.39;
        stroke-dashoffset: -60;
      }
    }

    #top,
    #bottom {
      stroke-dasharray: 30, 75.39;
      transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
    }
  }

  .nav-footer-btns {
    display: flex;
    align-items: center;
    gap: 20px;
    .drop-down-language-menu {
      position: relative;
      user-select: none;
      .language-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        border: 1px solid $secondaryColor;
        border-radius: 4px;
        outline: none !important;
        padding: 15px 10px;
        cursor: pointer;
      }
      .dropdown-menu-lg {
        position: absolute;
        top: 106%;
        left: 0%;
        width: 100%;
        background-color: #fff;
        border: 1px solid $secondaryColor;
        border-radius: 4px;
        z-index: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        padding: 10px 20px;
        font-size: 18px;
        cursor: pointer;
        display: none;
        &.active {
          display: block;
        }
        .dropdown-item {
          padding: 7px 0 !important;
        }
        .dropdown-item:last-child {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1px;
            background-color: #cdcdcd;
          }
        }
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .brand-logo {
      width: 20%;
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;

      .nav-items {
        display: inline-flex;
        margin-bottom: 0rem !important;
        gap: 20px;

        .nav-item {
          list-style: none;

          a {
            color: #777;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.5);

            &:hover {
              font-weight: bold;
              letter-spacing: 2px;
            }
          }
        }
      }
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 400px;
  height: 100%;
  background: $mainColor;
  padding: 2rem 30px;
  overflow-y: scroll;
  display: none;
  transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 9999;
  align-items: center;
  &.active {
    left: 0;
  }

  @media screen and (max-width: 995px) {
    display: block;
  }

  @media screen and (max-width: 760px) {
    width: 65%;
  }

  .res-cross {
    position: absolute;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      transform: rotate(360deg);
    }
    &:hover i {
      transform: rotate(-360deg);
    }

    i {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &:hover {
        transition: all 0.4s ease-in;
        animation: spin 1s linear infinite;
      }
    }
  }

  .side-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    .nav-item {
      font-size: 20px;
      font-weight: 700;
      position: relative;
      padding: 20px 10px;
      color: #fff;
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &::before {
        content: "";
        position: absolute;
        left: 0;
        transition: all 0.5s cubic-bezier(0.78, 0.04, 0, 0.9);
        top: 0;
        width: 2px;
        height: 100%;
        background-color: $secondaryColor;
        z-index: -1;
      }
      &:hover::before {
        width: 100%;
      }
      a {
        color: #fff;
      }
      a:not(:last-child) {
        border-bottom: 1px solid #d4d4d4;
        padding: 10px 0;
      }
      ul > li {
        font-size: 18px;

        i {
          padding-right: 15px;
          text-align: left;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #cdcdcd;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2rem $secondaryColor;
  }
}

.bg---dark {
  background-color: #5a81ff8a;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  transition: all 20s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.active {
    display: block;
  }

  @media (min-width: 993px) {
    display: none !important;
  }
}

.hero-section {
  width: 100%;
  padding-top: 12rem;
  padding-bottom: 4rem;
  position: relative;
  height: 100vh;
  background: #000 url("assets/images/hero/1.png") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 780px) {
    .text-center-mobile {
      text-align: center;
      > .d-flex {
        justify-content: center;
      }
    }
  }

  .hero-title,
  .hero-desc {
    color: #fff;
  }

  .hero-title {
    font-size: 55px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .hero-desc {
    font-size: 25px;
    padding: 20px 0;
  }
}

// Custom Video Player
.play-btn {
  background: $mainColor;
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: $mainColor;
    z-index: 1;
    animation: pulse-border 1500ms ease-out infinite;
  }
  img {
    width: 17px;
    z-index: 1;
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#feature {
  .features-gridx {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .single-feature {
      box-shadow: 0 0 2px 0px #777;
      width: 100%;
      padding: 20px;
      margin: 15px 7px;
      &:nth-child(2) {
        background: $mainColor;
        box-shadow: 0 0 2px 2px #777;
        color: #fff !important;
      }
      h4 {
        margin: 20px 0;
      }
      > .img-fluid {
        width: 60px;
      }
    }
  }
}

#about {
  ul {
    li {
      position: relative;
      list-style: none;
      margin: 10px 0;
      padding-left: 40px;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        background: url(assets/images/about/list.svg) no-repeat;
        width: 80px;
        height: 80px;
      }
    }
  }

  .col-md-6 {
    > figure {
      &:hover img:first-child {
        right: -60px;
      }

      position: relative;

      img:first-child {
        position: absolute;
        top: -20px;
        right: 0;
        width: 400px;
        height: auto;
        z-index: -1;
        transition: all 0.6s ease;
      }

      img:last-child {
        width: 500px;
        height: auto;
        margin: 6rem 0 0 0;
      }
    }
  }
}

#video {
  position: relative;

  figure {
    position: relative;
    height: 100%;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background-color: #202020c2;
      height: 100%;
    }
  }
  .play-btn {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#team {
  position: relative;

  .team-slider {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1px;

    .team-member {
      width: 100%;
      margin: 10px 8px;
      position: relative;

      > img {
        filter: grayscale(1);
        transition: all 0.2s linear;
      }

      &:hover .main-info {
        bottom: 0;
        opacity: 1;
      }

      &:hover img {
        filter: grayscale(0);
      }

      .main-info {
        text-align: center;
        padding: 10px 0;
        background-color: rgba(240, 248, 255, 0.733);
        backdrop-filter: blur(10px);
        opacity: 0;
        position: absolute;
        left: 0;
        bottom: -100%;
        width: 100%;
        transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        h4 {
          font-weight: bold;
          color: $secondaryColor;
        }

        p {
          font-weight: 500;
          color: #777;
        }

        .social-icons {
          a {
            > i {
              color: #777;
              font-size: 15px;
              padding-left: 6px;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}
.single-blog {
  .blog-img {
    a {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  .blog-content {
    background-color: #fff;
    padding: 8px 0;
    .blog-meta {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      .blog-author {
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
          width: 50px;
        }

        span {
          color: #313450;
          font-weight: 600;
          font-size: 18px;
          margin-left: 10px;
        }
      }

      .blog-comment {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          color: #505478;
          margin-left: 10px;
        }
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .blog-comment {
          display: none;
        }
      }
    }
  }

  .blog-title {
    h4 {
      font-weight: 600;
      a {
        color: #777;
      }
    }
  }
}

#contact {
  label {
    margin-bottom: 10px;
    color: var(--thm-b-text);
    font-weight: 500;
  }
  input[type="number"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  input[type="search"],
  select,
  textarea,
  .form-control {
    position: relative;
    display: block;
    height: 60px;
    width: 100%;
    font-size: 16px;
    color: #686a6f;
    font-weight: 400;
    padding: 14px 30px;
    letter-spacing: 0.1em;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    border-radius: 0;
    transition: all 300ms ease;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
  }
  .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  textarea {
    height: auto;
    resize: none;
    line-height: 1;
  }
  .button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: $secondaryColor;
    border: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    padding: 17px 30px;
    transition: 0.3s;
    text-transform: uppercase;
    border-radius: 0;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .sigma_contact-map {
    width: 100%;
    height: 600px;
    position: relative;
    margin-top: 4rem;
  }
  .sigma_contact-map iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
}

/* Footer Styles */
.footer {
  background: $mainColor;
  padding-top: 80px;
  padding-bottom: 40px;

  .single_footer {
    @media only screen and (max-width: 768px) {
      margin-bottom: 30px;
    }

    h4 {
      color: #fff;
      margin-top: 0;
      margin-bottom: 25px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 20px;

      &::after {
        content: "";
        display: block;
        height: 2px;
        width: 40px;
        background: #fff;
        margin-top: 20px;
      }
    }

    p {
      color: #fff;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a {
          color: #fff;
          transition: all 0.3s ease 0s;
          line-height: 36px;
          font-size: 15px;
          text-transform: capitalize;

          &:hover {
            color: #ff3666;
          }
        }
      }
    }
  }

  .single_footer_address {
    ul {
      li {
        color: #fff;

        span {
          font-weight: 400;
          color: #fff;
          line-height: 28px;
        }
      }
    }
  }

  .contact_social {
    ul {
      list-style: outside none none;
      margin: 0;
      padding: 0;
    }
  }

  /* Newsletter Styles */
  .subscribe {
    display: block;
    position: relative;
    margin-top: 15px;
    width: 100%;

    &__input {
      background-color: #fff;
      border: medium none;
      border-radius: 5px;
      color: #333;
      display: block;
      font-size: 15px;
      font-weight: 500;
      height: 60px;
      letter-spacing: 0.4px;
      margin: 0;
      padding: 0 150px 0 20px;
      text-align: left;
      text-transform: capitalize;
      width: 100%;

      @media only screen and (max-width: 768px) {
        padding: 0 50px 0 20px;
      }
    }

    &__btn {
      background-color: transparent;
      border-radius: 0 25px 25px 0;
      color: #01c7e9;
      cursor: pointer;
      display: block;
      font-size: 20px;
      height: 60px;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;

      i {
        transition: all 0.3s ease 0s;
      }

      @media only screen and (max-width: 768px) {
        right: 0px;
      }

      &:hover i {
        color: #ff3666;
      }
    }

    button {
      padding: 0;
      border: none;
      background-color: transparent;
      border-radius: 0;
    }
  }

  /* Social Profile Styles */
  .social_profile {
    margin-top: 40px;

    ul {
      list-style: outside none none;
      margin: 0;
      padding: 0;

      li {
        float: left;

        a {
          text-align: center;
          border: 0px;
          text-transform: uppercase;
          transition: all 0.3s ease 0s;
          margin: 0px 5px;
          font-size: 18px;
          color: #fff;
          border-radius: 30px;
          width: 50px;
          height: 50px;
          line-height: 50px;
          display: block;
          border: 1px solid rgba(255, 255, 255, 0.2);

          @media only screen and (max-width: 768px) {
            margin-right: 10px;
            margin-bottom: 10px;
          }

          @media only screen and (max-width: 480px) {
            width: 40px;
            height: 40px;
            line-height: 40px;
          }

          &:hover {
            background: #ff3666;
            border: 1px solid #ff3666;
            color: #fff;
            border: 0px;
          }
        }
      }
    }
  }

  /* Copyright Styles */
  .copyright {
    margin-top: 70px;
    padding-top: 40px;
    color: #fff;
    font-size: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;

    a {
      color: #01c7e9;
      transition: all 0.2s ease 0s;

      &:hover {
        color: #ff3666;
      }
    }
  }
}

// To Top Button

#scrollUp {
  padding: 20px;
  position: fixed;
  right: -100px;
  bottom: 30px;
  z-index: 999;
  transition: all 0.6s linear;
  transform: rotate(-90deg);
  cursor: pointer;
  &.active {
    right: 10px;
    transform: rotate(-90deg);
  }
  img {
    border-radius: 50%;
    transform: rotate(90deg);
  }
  i {
    font-size: 20px;
    color: $secondaryColor;
  }
}

// Custom Scrollbar
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $mainColor;
}

// Custom Loading
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  img {
    animation: scaleAnimation 1.5s ease-in-out infinite;
    transition: all 0.3s linear;
    width: 100px;
  }
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
